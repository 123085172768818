<template>
  <div>
    <hero-bar>
      Super Admin
    </hero-bar>
    <section class="section is-main-section">
      <card-component
        class="has-table has-mobile-sort-spaced"
        title="Postman"
        icon="fa-robot"
      >
        <div class="py-2 px-4">
          <div class="field mb-3">
            <label class="label">Method Name</label>
            <div class="control">
              <input class="input" type="text" placeholder="Text input" v-model="postman.methodName">
            </div>
          </div>
          <div class="field mb-3">
            <label class="label">JSON Data</label>
            <div class="control">
              <textarea class="textarea" placeholder="Textarea" v-model="postman.jsonStr"></textarea>
            </div>
          </div>
          <div class="field is-grouped">
            <div class="control">
              <button @click="sendPostman()" class="button is-primary">Submit</button>
            </div>
          </div>
          <article v-if="postman.response" class="message">
            <div class="message-body">
              {{ postman.response }}
            </div>
          </article>
        </div>
      </card-component>

    </section>
  </div>
</template>

<script>
import firebaseApp from '@/firebase/init'
import CardComponent from '@/components/CardComponent'
import HeroBar from '@/components/HeroBar'

export default {
  name: 'Super Admin',
  components: {
    HeroBar,
    CardComponent
  },
  data () {
    return {
      postman: {
        loading: false,
        methodName: 'change-godmode-email adjust-concurrent-devices',
        jsonStr: '{ "email": "", "maxConcurrentUsers": 9999 }',
        response: null
      }
    }
  },
  methods: {
    sendPostman () {
      this.postman.loading = true
      const masterFunctionAdmin = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunctionAdmin')
      let postmanObj = { methodName: this.postman.methodName }
      postmanObj = Object.assign(postmanObj, JSON.parse(this.postman.jsonStr))
      console.log(postmanObj)
      masterFunctionAdmin(postmanObj).then(res => {
        this.postman.loading = false
        this.postman.response = res.data
      }).finally(() => {
        this.postman.loading = false
      })
    }
  }
}
</script>
