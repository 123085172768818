<template>
  <card-component title="Edit Profile" icon="fa-user">
    <form v-if="form" @submit.prevent="() => {}">
      <!-- <b-field horizontal label="Avatar">
        <file-picker />
      </b-field>
      <hr /> -->
      <b-field horizontal label="Name">
        <b-input v-model="form.name" name="name" :placeholder="profile.name ? profile.name : ''" required />
      </b-field>
      <b-field horizontal label="E-mail" message="Cannot be changed">
        <b-input :value="email" name="email" type="email" readonly disabled />
      </b-field>
      <hr />
      <b-field horizontal>
        <div class="control">
          <div v-if="errorMessage" class="container">
            <div class="is-size-6 has-text-danger">
              {{ errorMessage }}
            </div>
          </div>
          <button
            @click="editProfile"
            class="button is-primary"
            :class="{ 'is-loading': loading }"
            :disabled="!allowEditProfile"
          >
            Save changes
          </button>
        </div>
      </b-field>
    </form>
  </card-component>
</template>

<script>
import firebaseApp from '@/firebase/init'
// import FilePicker from '@/components/FilePicker'
import CardComponent from '@/components/CardComponent'

export default {
  name: 'ProfileUpdateForm',
  components: {
    CardComponent
    // FilePicker
  },
  data () {
    return {
      allowEditProfile: false,
      errorMessage: null,
      isFileUploaded: false,
      loading: false,
      form: {
        name: null,
        email: null
      }
    }
  },
  computed: {
    email () {
      return this.$store.state.user.email
    },
    profile () {
      return this.$store.state.profile
    }
  },
  mounted () {
    this.form.name = this.profile.name
    this.form.email = this.userEmail
  },
  methods: {
    editProfile () {
      this.loading = true
      this.errorMessage = null
      const masterFunctionAdmin = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunctionAdmin')
      masterFunctionAdmin({
        methodName: 'edit-profile',
        name: this.form.name
      }).then(() => {
        this.$buefy.toast.open({
          message: 'Successfully edited your profile!',
          type: 'is-primary',
          queue: false
        })
        this.loading = false
      }).catch(err => {
        this.errorMessage = err.message
        this.loading = false
      })
    }
  },
  watch: {
    form: {
      handler () {
        if (
          this.form.name && this.form.name.length > 0
        ) {
          this.allowEditProfile = true
        } else {
          this.allowEditProfile = false
        }
      },
      deep: true
    }
  }
}
</script>
