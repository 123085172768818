<template>
  <card-component title="Change teams' points" icon="fa-star" class="pb-5">
    <form @submit.prevent="() => {}">
      <b-field horizontal label="Select teams">
        <div class="buttons">
          <div
          @click="selectTeam(null)"
          class="button is-small"
          :class="{'is-primary': teamCodeHashes.length === adventureTeams.length }"
          >
            ALL TEAMS
          </div>
          <div
          @click="selectTeam(adventureTeam.teamCodeHash)"
          v-for="(adventureTeam, index) in adventureTeams"
          :key="index"
          class="button is-small"
          :class="{'is-primary': teamCodeHashes.includes(adventureTeam.teamCodeHash) && teamCodeHashes.length !== adventureTeams.length }"
          >
            {{ adventureTeam.teamName }}
          </div>
        </div>
      </b-field>
      <b-field horizontal label="Points">
        <b-input class="noscroll" v-model="points" type="number" name="points"/>
      </b-field>
      <b-field horizontal label="Remarks (optional)">
        <b-input v-model="remarks" name="remarks"/>
      </b-field>
      <b-field
        label="Leaderboard only"
        message="Make changes only to points on the leaderboard, without affecting teams' progress in the game. This is useful if you want to give extra points to teams but still require them to complete all other tasks in the chapter as per normal."
        horizontal
      >
        <b-switch v-model="leaderboardOnly">
        </b-switch>
      </b-field>
      <b-field horizontal>
        <div class="control">
          <div v-if="errorMessage" class="container">
            <div class="is-size-6 has-text-danger">
              {{ errorMessage }}
            </div>
          </div>
          <button
            @click="adjustPoints"
            class="button is-primary"
            :class="{ 'is-loading': loading }"
            :disabled="!points || points === 0"
          >
            Adjust points {{ points ? `by ${points}` : '' }}
          </button>
        </div>
      </b-field>
    </form>
  </card-component>
</template>

<script>
import firebaseApp from '@/firebase/init'
import CardComponent from '@/components/CardComponent'

export default {
  name: 'ChangeTeamPoints',
  components: {
    CardComponent
  },
  props: {
    adventureTeams: {
      type: Array,
      default: null
    },
    faciKey: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      teamCodeHashes: [],
      errorMessage: null,
      loading: false,
      remarks: null,
      points: null,
      leaderboardOnly: true
    }
  },
  methods: {
    selectTeam (teamCodeHash) {
      if (teamCodeHash) {
        if (this.teamCodeHashes.length === this.adventureTeams.length) {
          this.teamCodeHashes = [teamCodeHash]
        } else if (
          this.teamCodeHashes.includes(teamCodeHash)
        ) {
          this.teamCodeHashes = this.teamCodeHashes.filter(el => {
            return el !== teamCodeHash
          })
        } else {
          this.teamCodeHashes.push(teamCodeHash)
        }
      } else {
        this.teamCodeHashes = this.adventureTeams.map(adventureTeam => {
          return adventureTeam.teamCodeHash
        })
      }
    },
    adjustPoints () {
      this.loading = true
      this.errorMessage = null
      const masterFunctionAdmin = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunctionAdmin')
      masterFunctionAdmin({
        methodName: 'change-team-points',
        facilitatorKey: this.faciKey,
        teamCodeHashes: this.teamCodeHashes,
        remarks: this.remarks,
        points: this.points,
        leaderboardOnly: this.leaderboardOnly
      }).then(result => {
        this.$buefy.toast.open({
          message: 'Successfully adjusted points!',
          type: 'is-primary',
          queue: false
        })
        this.points = null
        this.remarks = null
        this.loading = false
      }).catch(err => {
        this.errorMessage = err.message
        this.loading = false
      })
    }
  },
  mounted () {
    this.teamCodeHashes = this.adventureTeams.map(adventureTeam => {
      return adventureTeam.teamCodeHash
    })
  },
  watch: {
    adventureTeams (newAdventureTeams, oldAdventureTeams) {
      if (
        newAdventureTeams && oldAdventureTeams &&
        newAdventureTeams.length !== oldAdventureTeams.length
      ) {
        this.teamCodeHashes = newAdventureTeams.map(adventureTeam => {
          return adventureTeam.teamCodeHash
        })
      }
    },
    points (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.points = parseInt(newValue.toString())
      }
    }
  }
}
</script>
