<template>
  <div>
    <hero-bar>
      Add Files
      <router-link :to="{name:'viewFiles'}" slot="right" class="button is-primary">
        <i class="fas fa-file-image mr-2"></i>
        View Files
      </router-link>
    </hero-bar>
    <section class="section is-main-section">
      <tiles>
        <files-add-module class="tile is-child" />
      </tiles>
    </section>
  </div>
</template>

<script>
import FilesAddModule from '@/components/FilesAdd'
import HeroBar from '@/components/HeroBar'
import Tiles from '@/components/Tiles'

export default {
  name: 'FilesAdd',
  components: {
    HeroBar,
    // ClientsTableSample,
    FilesAddModule,
    Tiles
    // Notification
  },
  mounted () {
    this.$mixpanel.track('page_visit', {
      distinct_id: this.$store.state.profile.organisationId,
      organisation_name: this.$store.state.organisation.name,
      user_email: this.$store.state.profile.email,
      page_name: this.$route.name
    })
  }
}
</script>
