<template>
  <div>
    <div v-if="customHeader" class="container has-text-centered mb-5">
      <div class="tag is-rounded is-size-4 has-text-weight-bold px-6">
        {{ customHeader }}
      </div>
    </div>
    <div class="is-flex is-justify-space-around" :class="{
      'px-6': isGalleryShown && isLeaderboardShown
    }">
      <div
        v-if="isGalleryShown"
        class="is-flex is-flex-direction-column is-align-items-center is-justify-content-center"
        style="width: 100%; height: 80vh;">
        <div v-if="!shownItem" class="box is-flex is-justify-content-center is-align-items-center" style="width: 60vw; opacity: 0.5;">
          <div class="has-text-weight-semibold">Waiting for submissions...</div>
        </div>

        <div
          class="box m-2 has-text-centered is-size-5"
          style="max-width: 250px;"
          v-if="shownItem && (shownItem.mimeType === 'text/plain' || !shownItem.url.includes('http'))">
          {{ shownItem.url }}
        </div>
        <img
          v-show="shownItem && (!shownItem.mimeType || shownItem.mimeType.includes('image'))"
          class="box is-rounded mb-0"
          style="max-height: 86%;"
          :src="shownItem ? shownItem.url : ''"
          :alt="shownItem ? shownItem.teamName : ''"
        >
        <video
          v-show="shownItem && (shownItem.mimeType && shownItem.mimeType.includes('video'))"
          id="video-player"
          class="box is-rounded mb-0"
          :src="shownItem ? shownItem.url : ''"
          autoplay>
          Your browser does not support the video tag.
        </video>
        <audio v-show="shownItem && (shownItem.mimeType && shownItem.mimeType.includes('audio'))" class="p-2 mb-0" style="width: 100%;" autoplay>
          <source :src="shownItem ? shownItem.url : ''" type="audio/mpeg">
        </audio>

        <div v-if="shownItem" class="has-text-centered mt-3">
          <span class="tag is-white is-medium is-rounded">{{ shownItem.teamName }}</span>
        </div>
      </div>
      <div
        v-if="isLeaderboardShown"
        class="is-flex is-flex-direction-column is-align-items-center"
        :class="{ 'leaderboard-small': isGalleryShown }"
        style="width: 100%; height: 80vh;"
      >
        <div
        v-for="(row, index) in ranking"
        :key="index"
        class="box is-flex is-justify-content-space-between mb-1"
        :style="{'width': '80%', 'max-width': '400px', 'opacity': '0.85', 'background-color': uiMods.customLeaderboardExhibitRankBackgroundColour ? uiMods.customLeaderboardExhibitRankBackgroundColour : ''}"
        >
          <div :style="{'color': uiMods.customLeaderboardExhibitRankTextColour ? uiMods.customLeaderboardExhibitRankTextColour : ''}">
            {{ index + 1 }}
            <span class="has-text-weight-bold ml-2">{{ row.teamName }}</span>
          </div>
          <div :style="{'color': uiMods.customLeaderboardExhibitRankTextColour ? uiMods.customLeaderboardExhibitRankTextColour : ''}">
            {{ row.points }} points
          </div>
        </div>
      </div>
      <div class="exhibit-buttons has-text-white">
        <i @click="isLeaderboardShown = !isLeaderboardShown" class="fas fa-sort-numeric-down is-clickable mr-3"></i>
        <i @click="isGalleryShown = !isGalleryShown" class="fas fa-images is-clickable mr-3"></i>
        <i v-if="!isFullScreen" @click="openFullscreen" class="fas fa-expand is-clickable mr-3"></i>
        <i v-if="isFullScreen" @click="closeFullscreen" class="fas fa-compress-arrows-alt is-clickable mr-3"></i>
        <i @click="setShowSpecificTasksOnly" class="fas fa-eye-slash is-clickable mr-3"></i>
        <i @click="setIntervalSpeed" class="fas fa-tachometer-alt is-clickable mr-3"></i>
        <i @click="setCustomHeader" class="fas fa-heading is-clickable mr-3"></i>
        <i @click="showBackgroundSetter" class="fas fa-image is-clickable mr-3"></i>
        <input v-show="isBackgroundSetterShown" type="file" id="fileInput">
        <!-- <i @click="changeShownItemIndex(-1)" class="fas fa-backward is-clickable mr-3"></i> -->
        <i v-if="isPaused" @click="togglePause" class="fas fa-play is-clickable mr-3"></i>
        <i v-if="!isPaused" @click="togglePause" class="fas fa-pause is-clickable mr-3"></i>
        <i @click="shufflerFunction" class="fas fa-forward is-clickable"></i>
      </div>
    </div>
  </div>
</template>

<script>
import firebaseApp from '@/firebase/init'

export default {
  name: 'Exhibit',
  components: {
  },
  data () {
    return {
      isGalleryShown: true,
      isLeaderboardShown: true,

      faciSessionUpdater: null,
      gallery: [],
      tasks: [],
      shownTasks: [],
      isPaused: false,
      shownItemIndex: -1,
      shownItemIndexShufflerInterval: null,
      intervalMillis: 10000,
      originalIntervalMillis: 10000,
      customHeader: null,
      customBackgroundUrl: null,
      isBackgroundSetterShown: false,
      isFullScreen: false,

      ranking: [],
      uiMods: null
    }
  },
  methods: {
    openFullscreen () {
      const elem = document.documentElement
      if (elem.requestFullscreen) {
        elem.requestFullscreen()
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen()
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen()
      }
      this.isFullScreen = true
    },
    closeFullscreen () {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      }
      this.isFullScreen = false
    },
    setShowSpecificTasksOnly () {
      const tasksStr = this.tasks.map((task, index) => {
        return `\n${index + 1}. Chapter ${task.stageIndex + 1}: ${task.name}`
      })
      const userInput = prompt(`Enter the task numbers to show. Media from other tasks will not be shown.\n\nWrite it as a comma-separated text. e.g. "1, 4, 6"\n${tasksStr}`)
      const newShownTasks = []
      userInput.split(',').forEach(taskIndex => {
        if (!isNaN(taskIndex)) {
          const task = this.tasks[taskIndex - 1]
          newShownTasks.push(
            task.stageIndex + task.name
          )
        }
      })
      this.shownItemIndex = -1
      this.shownTasks = newShownTasks
    },
    setIntervalSpeed () {
      const userInput = prompt('Enter interval speed in seconds:')
      let seconds = Number(userInput)
      if (isNaN(seconds)) {
        seconds = 5
        alert('Not a number, defaulting to 5 seconds.')
      }
      if (seconds) {
        this.originalIntervalMillis = seconds * 1000
        this.intervalMillis = seconds * 1000
      }
    },
    showBackgroundSetter () {
      if (this.customBackgroundUrl) {
        const confirmed = confirm('Remove current background?')
        if (confirmed) {
          this.customBackgroundUrl = null
          localStorage.removeItem('playToursExhibitBackgroundImage')
        } else return
      }
      this.isBackgroundSetterShown = !this.isBackgroundSetterShown
    },
    setCustomHeader () {
      this.customHeader = prompt('Enter the custom header:')
      localStorage.setItem('playToursExhibitCustomHeader', this.customHeader)
    },
    togglePause () {
      this.isPaused = !this.isPaused
    },
    changeShownItemIndex (diff) {
      if (this.shownItemIndex + diff >= this.gallery.length) this.shownItemIndex = 0
      else if (this.shownItemIndex + diff < 0) this.shownItemIndex = this.gallery.length
      else this.shownItemIndex = this.shownItemIndex + diff
      this.clearShufflerInterval()
      this.setBackground()
      this.shownItemIndexShufflerInterval = setInterval(
        this.shufflerFunction, this.intervalMillis)
    },
    getExhibitData () {
      const masterFunctionAdmin = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunctionAdmin')
      masterFunctionAdmin({
        methodName: 'get-exhibit-data',
        sessionId: this.$route.params.sessionId,
        getRanking: true
      }).then(result => {
        this.gallery = result.data.gallery ? result.data.gallery.slice().reverse() : []
        this.tasks = result.data.tasks
        this.ranking = result.data.ranking || this.ranking
        this.uiMods = result.data.uiMods
      })
    },
    shufflerFunction () {
      this.clearShufflerInterval()
      const n = this.gallery.length - 1
      const x = this.shownItemIndex
      let randomNum = 0
      if (this.gallery.length > 1) {
        do {
          randomNum = Math.floor(Math.random() * (n - 0 + 1)) + 0
        } while (
          randomNum === x ||
          (
            this.shownTasks.length > 0 &&
            this.gallery.length > 0 &&
            this.gallery.some(x => {
              return this.shownTasks.includes((x.stage - 1) + x.taskName)
            }) &&
            !this.shownTasks.includes(
              (this.gallery[randomNum].stage - 1) + this.gallery[randomNum].taskName
            )
          )
        )
      }
      this.shownItemIndex = !this.isPaused ? randomNum : this.shownItemIndex
      this.changeIntervalIfVideoElseContinue()
    },
    startItemIndexShuffler () {
      this.clearShufflerInterval()
      this.shufflerFunction()
    },
    clearShufflerInterval () {
      if (this.shownItemIndexShufflerInterval) {
        clearInterval(this.shownItemIndexShufflerInterval)
      }
    },
    setBackground () {
      let backgroundUrl = null
      if (this.shownItem && this.shownItem.mimeType.includes('image') && this.shownItem.url) backgroundUrl = this.shownItem.url
      if (this.customBackgroundUrl) backgroundUrl = this.customBackgroundUrl

      if (document.getElementById('exhibit-background')) document.getElementById('exhibit-background').remove()

      const backgroundDiv = document.createElement('div')
      // Set the CSS styles for the background div
      backgroundDiv.id = 'exhibit-background'
      if (backgroundUrl) backgroundDiv.style.backgroundImage = `url('${backgroundUrl}')`
      backgroundDiv.style.backgroundSize = 'cover'
      backgroundDiv.style.backgroundPosition = 'center'
      backgroundDiv.style.backgroundColor = 'black'
      const blurConfig = `blur(${this.customBackgroundUrl ? '0' : '20'}px)`
      backgroundDiv.style.filter = blurConfig
      backgroundDiv.style.webkitFilter = blurConfig
      backgroundDiv.style.position = 'fixed'
      backgroundDiv.style.top = '0'
      backgroundDiv.style.left = '0'
      backgroundDiv.style.width = '100vw'
      backgroundDiv.style.height = '100vh'
      backgroundDiv.style.zIndex = '-1'
      // Append the background div to the body
      document.body.appendChild(backgroundDiv)
    },
    changeIntervalIfVideoElseContinue () {
      if (!(this.shownItem && this.shownItem.mimeType && this.shownItem.mimeType.includes('video'))) {
        this.intervalMillis = this.originalIntervalMillis
        this.setBackground()
        this.shownItemIndexShufflerInterval = setInterval(
          this.shufflerFunction, this.intervalMillis)
      }
    },
    setCustomBackgroundHandler () {
      const fileInput = document.getElementById('fileInput')
      fileInput.addEventListener('change', (event) => {
        const file = event.target.files[0]
        const reader = new FileReader()
        reader.addEventListener('load', (event) => {
          this.customBackgroundUrl = event.target.result
          localStorage.setItem('playToursExhibitBackgroundImage', this.customBackgroundUrl)
          this.setBackground()
          this.isBackgroundSetterShown = false
        })
        reader.readAsDataURL(file)
      })
    },
    checkCustomBackgroundFromLocalStorage () {
      const savedImage = localStorage.getItem('playToursExhibitBackgroundImage')
      if (savedImage) this.customBackgroundUrl = savedImage
    },
    checkCustomHeaderFromLocalStorage () {
      const customHeader = localStorage.getItem('playToursExhibitCustomHeader')
      if (customHeader) this.customHeader = customHeader
    }
  },
  watch: {
    faciSessionUpdater (updated, current) {
      if (
        updated && current &&
        updated.updateCount > current.updateCount
      ) {
        this.getExhibitData()
      }
    },
    gallery (updated, current) {
      if (updated.length > current.length) {
        this.shufflerFunction()
      }
    }
  },
  computed: {
    shownItem () {
      if (this.shownItemIndex < 0) return null
      return this.gallery[this.shownItemIndex]
    }
  },
  mounted () {
    const body = document.getElementsByTagName('body')[0]
    body.style.paddingLeft = '0px'
    body.style.overflow = 'hidden'
    const html = document.getElementsByTagName('html')[0]
    html.style.overflow = 'hidden'

    this.getExhibitData()

    const faciSessionUpdaters = firebaseApp.firestore().collection('faciSessionUpdaters')
    if (this.$route.params.sessionId) {
      this.$bind('faciSessionUpdater', faciSessionUpdaters.doc(this.$route.params.sessionId))
    } else {
      alert('Invalid Session ID')
    }

    const videoPlayer = document.getElementById('video-player')
    videoPlayer.addEventListener('loadedmetadata', () => {
      const duration = videoPlayer.duration
      this.intervalMillis = duration * 1000
    })
    videoPlayer.addEventListener('loadeddata', () => {
      videoPlayer.play()
      this.setBackground()
      this.shownItemIndexShufflerInterval = setInterval(
        this.shufflerFunction, this.intervalMillis)
    }, false)

    this.setCustomBackgroundHandler()
    this.checkCustomBackgroundFromLocalStorage()
    this.checkCustomHeaderFromLocalStorage()
    this.setBackground()
  },
  beforeDestroy () {
    this.clearShufflerInterval()
  }
}

</script>

<style>
.frame {
  width: 80%;
  height: 80%;
}

.exhibit-buttons {
  position: fixed;
  left: 10px;
  bottom: 10px;
}

.leaderboard-small {
  max-width: 30%
}
</style>
