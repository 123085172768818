<template>
  <div>
    <b-table
      :checked-rows.sync="checkedRows"
      :checkable="checkable"
      :loading="isLoading"
      :paginated="paginated"
      :per-page="perPage"
      :striped="true"
      :hoverable="true"
      :data="masterTableRows"
    >
      <b-table-column label="Clan" field="clan" sortable v-slot="props">
        {{ props.row.clan }}
      </b-table-column>
      <b-table-column label="Points" field="points" sortable v-slot="props">
        {{ props.row.points }}
      </b-table-column>
      <b-table-column label="Averaged Points" field="points" sortable v-slot="props">
        {{ props.row.averagedPoints }}
      </b-table-column>
      <b-table-column label="No. of teams with > 0 points" field="points" sortable v-slot="props">
        {{ props.row.clanMembers.length }}
      </b-table-column>
      <b-table-column label="Teams with > 0 points" field="points" sortable v-slot="props">
        {{ props.row.clanMembers.map(member => member.teamName).join(', ') }}
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
export default {
  name: 'AdventureTeamsTable',
  props: {
    adventureTeams: {
      type: Array,
      default: null
    },
    ranking: {
      type: Array,
      default: null
    },
    checkable: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isModalActive: false,
      trashObject: null,
      isLoading: false,
      paginated: true,
      perPage: 20,
      checkedRows: []
    }
  },
  computed: {
    masterTableRows () {
      const clans = [...new Set(this.ranking.map(item => item.clan))]
      const clanRanking = clans
        .filter(x => !!x)
        .map(clan => {
          const clanMembers = this.ranking.filter(item => item.clan === clan.trim() && item.points > 0)
          const points = clanMembers.reduce((sum, el) => sum + el.points, 0)
          const averagedPoints = points / clanMembers.length
          return {
            clan,
            points,
            clanMembers,
            averagedPoints
          }
        })
        .sort((a, b) => b.averagedPoints - a.averagedPoints)
        .map((el, index) => ({ ...el, rank: index + 1 }))
      return clanRanking
    }
  }
}
</script>
