<template>
  <card-component title="Change Password" icon="fa-lock">
    <button
      @click="resetPassword"
      type="submit"
      class="button is-primary"
      :class="{ 'is-loading': loading }"
    >
      Send password change email
    </button>
  </card-component>
</template>

<script>
import firebaseApp from '@/firebase/init'
import CardComponent from '@/components/CardComponent'

export default {
  name: 'PasswordUpdateForm',
  components: {
    CardComponent
  },
  data () {
    return {
      loading: false,
      errorMessage: null
    }
  },
  methods: {
    resetPassword () {
      this.loading = true
      this.errorMessage = null
      firebaseApp.auth().sendPasswordResetEmail(this.email).then(() => {
        this.loading = false
        this.$buefy.toast.open({
          message: `Done! An email with a link to change your password has been sent to ${this.email}.`,
          type: 'is-primary',
          queue: false
        })
      }).catch((error) => {
        this.errorMessage = error.message
      })
    }
    // changePassword () {
    //   this.loading = true
    //   this.errorMessage = null

    //   const user = firebaseApp.auth().currentUser

    //   const credential = firebase.auth.EmailAuthProvider.credential(
    //     this.email,
    //     this.form.passwordCurrent
    //   )

    //   user.reauthenticateWithCredential(credential).then(() => {
    //     return user.updatePassword(this.form.password)
    //   }).then(() => {
    //     this.form = {
    //       passwordCurrent: null,
    //       password: null,
    //       password2: null
    //     }
    //     this.loading = false
    //     this.$buefy.toast.open({
    //       message: 'Password changed successfully.',
    //       type: 'is-primary',
    //       queue: false
    //     })
    //     return true
    //   }).catch((error) => {
    //     this.loading = false
    //     this.errorMessage = error.message
    //   })
    // }
  },
  computed: {
    email () {
      return this.$store.state.user.email
    }
  }
}
</script>
