<template>
  <div>
    <section class="section is-main-section pt-3 pb-2">
      <card-component
        class="has-table has-mobile-sort-spaced"
        title="Validate Completion Certificates"
        icon="fa-certificate"
      >
        <div class="container px-5 pb-5 pt-3">
          <div v-if="!completionCert">
            <FormInput v-if="!loading" :hideLabel="true" :title="'Name'" :type="'qrbarcode'" :value="certNumber" v-on:update="certNumber = $event"/>
            <div v-if="loading" class="container has-text-centered">
              <progress class="progress is-small is-primary" max="100">15%</progress>
            </div>
          </div>
          <div v-if="completionCert">
            <div class="container">
              <div
              @click="reset()"
              class="button is-rounded is-small is-primary is-outlined is-white"
              >
              <i class="fas fa-arrow-left mr-2"></i>
                Scan another
              </div>
            </div>
            <div class="container has-text-centered">
              <span v-if="!completionCert.validated" class="tag is-success is-rounded">Not validated</span>
              <span v-if="completionCert.validated" class="tag is-warning is-rounded">Validated</span>
            </div>
            <div class="is-size-5 has-text-weight-semibold has-text-centered">
              Completion Certificate
            </div>
            <div class="is-size-6 has-text-weight-semibold has-text-centered mb-3">
              Cert Number: {{ completionCert.certNumber }}
            </div>
            <div class="has-text-weight-semibold is-size-6">
              Certificate details
            </div>
            <div class="container is-size-6">
              Title: {{ completionCert.adventureName }}
            </div>
            <div class="container is-size-6">
              Started: {{ (new Date(completionCert.startedAt._seconds * 1000)).toLocaleString('en-SG') }}
            </div>
            <div class="container is-size-6">
              Completed: {{ (new Date(completionCert.completedAt._seconds * 1000)).toLocaleString('en-SG') }}
            </div>
            <div class="container is-size-6">
              Points: {{ completionCert.totalPoints }}
            </div>
            <div class="container is-size-6">
              Duration: {{ ((completionCert.completedAt._seconds - completionCert.startedAt._seconds)/60).toFixed(2) }} minutes
            </div>
            <div class="container is-size-6">
              Players ({{ completionCert.teamMembers.length }}): {{ completionCert.teamMembers.map(x => x.name).join(', ') }}
            </div>
            <div class="container is-size-6">
              Validated: {{ completionCert.validatedAt ? (new Date(completionCert.validatedAt._seconds * 1000)).toLocaleString('en-SG') : 'Not validated' }}
            </div>
            <div
            v-if="!completionCert.validated"
            @click="validateCert()"
            class="button is-primary is-fullwidth mt-4"
            :class="{'is-outlined': timesClicked === 0}"
            >
              {{ timesClicked === 0 ? 'Click 2 times to validate' : 'Click to validate' }}
            </div>
          </div>
        </div>
      </card-component>
    </section>
  </div>
</template>

<script>
import FormInput from '@/components/Forms/FormInput'
import CardComponent from '@/components/CardComponent'
import firebaseApp from '@/firebase/init'

export default {
  name: 'CertificateValidator',
  components: {
    CardComponent,
    FormInput
  },
  data () {
    return {
      certValidatorKey: this.$route.params.certValidatorKey,
      completionCert: null,
      errorMessage: null,
      certNumber: null,
      loading: false,
      timesClicked: 0
    }
  },
  methods: {
    reset () {
      this.certNumber = null
      this.completionCert = null
      this.timesClicked = 0
      this.loading = false
    },
    checkCert () {
      this.loading = true
      this.completionCert = null
      const certNumber = this.certNumber.split('/cert?number=')[1]
      const masterFunctionAdmin = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunctionAdmin')
      masterFunctionAdmin({
        methodName: 'check-cert',
        certValidatorKey: this.certValidatorKey,
        certNumber
      }).then(result => {
        this.completionCert = result.data.completionCert
      }).catch(err => {
        this.certNumber = null
        this.$buefy.toast.open({
          message: err.message,
          type: 'is-primary',
          queue: false
        })
      }).finally(() => {
        this.loading = false
      })
    },
    validateCert () {
      if (this.timesClicked === 0) {
        this.timesClicked = 1
        return
      }
      this.loading = true
      const certNumber = this.certNumber.split('/cert?number=')[1]
      const masterFunctionAdmin = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunctionAdmin')
      masterFunctionAdmin({
        methodName: 'validate-cert',
        certValidatorKey: this.certValidatorKey,
        certNumber
      }).then(() => {
        this.completionCert.validated = true
      }).catch(err => {
        this.$buefy.toast.open({
          message: err.message,
          type: 'is-primary',
          queue: false
        })
      }).finally(() => {
        this.loading = true
        this.timesClicked = 0
      })
    }
  },
  watch: {
    certNumber (newCertNumber, oldCertNumber) {
      if (newCertNumber && !oldCertNumber) {
        this.checkCert()
      }
    }
  }
}
</script>

<style>

</style>
