<template>
  <card-component :title="`Team ${judgement.teamName}'s Answer`" icon="fa-running">
    <div v-if="judgement.challengeDetails.type === 'judged-image'" class="container has-text-centered p-3">
      <img
      :src="judgement.value"
      >
    </div>
    <div v-if="judgement.challengeDetails.type === 'judged-video'" class="container has-text-centered p-3">
      <video :src="judgement.value" width="320" height="240" controls>
        Your browser does not support the video tag.
      </video>
    </div>
    <div v-if="judgement.challengeDetails.type === 'judged-text'" class="container p-3">
      <div>
        {{ judgement.value }}
      </div>
    </div>
    <div v-if="judgement.challengeDetails.type === 'judged-audio'" class="container p-3">
      <audio
        :src="judgement.value"
        controls="controls"
        class="mb-1"
        style="width: 100%;"
      >
        <source
          style="display: block;margin-left: auto;margin-right: auto;"
          type="audio/wav"
        >
      </audio>
    </div>
    <div v-if="judgement.challengeDetails.type === 'judged-file'" class="container p-3">
      <file-preview :file="{judgement}"></file-preview>
    </div>
  </card-component>
</template>

<script>
import CardComponent from '@/components/CardComponent'
import FilePreview from '@/components/FilePreview'

export default {
  name: 'JudgementAnswer',
  components: {
    CardComponent,
    FilePreview
  },
  props: {
    judgement: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      files: []
    }
  },
  methods: {
  },
  mounted () {
  }
}
</script>

<style>
.files {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap
}

.files-image {
  max-width: 300px;
  max-height: 300px;
}
</style>
