<template>
  <card-component title="Task Details" icon="fa-running">
    <div class="p-3" v-if="judgement" v-markdown>{{ judgement.challengeDetails.briefing }}</div>
  </card-component>
</template>

<script>
import CardComponent from '@/components/CardComponent'

export default {
  name: 'JudgementView',
  components: {
    CardComponent
  },
  props: {
    judgement: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      files: []
    }
  },
  methods: {
  },
  mounted () {
  }
}
</script>

<style>
.files {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap
}

.files-image {
  max-width: 300px;
  max-height: 300px;
}
</style>
