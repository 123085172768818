<template>
  <card-component title="Change teams' start time" icon="fa-forward" class="pb-5">
    <form @submit.prevent="() => {}">
      <b-field horizontal label="Select teams">
        <div class="buttons">
          <div
          @click="selectTeam(null)"
          class="button is-small"
          :class="{'is-primary': teamCodeHashes.length === adventureTeams.length }"
          >
            ALL TEAMS
          </div>
          <div
          @click="selectTeam(adventureTeam.teamCodeHash)"
          v-for="(adventureTeam, index) in sortedAdventureTeams"
          :key="index"
          class="button is-small"
          :class="{'is-primary': teamCodeHashes.includes(adventureTeam.teamCodeHash) && teamCodeHashes.length !== adventureTeams.length }"
          >
            {{ adventureTeam.teamName }} ({{ adventureTeam.stage }})
          </div>
        </div>
      </b-field>
      <!-- <b-field horizontal label="Chapter">
        <b-input v-model="stage" type="number" name="stage"/>
      </b-field> -->
      <b-field horizontal label="Start time">
        <input id="datetime" type="datetime-local" v-model="timestamp"/>
      </b-field>
      <b-field horizontal label="Remarks (optional)">
        <b-input v-model="remarks" name="remarks"/>
      </b-field>
      <b-field horizontal>
        <div class="control">
          <div v-if="errorMessage" class="container">
            <div class="is-size-6 has-text-danger">
              {{ errorMessage }}
            </div>
          </div>
          <button
            @click="adjustTimestamp"
            class="button is-primary"
            :class="{ 'is-loading': loading }"
            :disabled="!(timestamp && teamCodeHashes.length > 0)"
          >
            Adjust to {{ timestamp ? timestamp.toLocaleString() : '...' }}
          </button>
        </div>
      </b-field>
    </form>
  </card-component>
</template>

<script>
import firebaseApp from '@/firebase/init'
import CardComponent from '@/components/CardComponent'

export default {
  name: 'ChangeTeamStage',
  components: {
    CardComponent
  },
  props: {
    adventureTeams: {
      type: Array,
      default: null
    },
    faciKey: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      teamCodeHashes: [],
      errorMessage: null,
      loading: false,
      remarks: null,
      timestamp: null
    }
  },
  methods: {
    selectTeam (teamCodeHash) {
      if (teamCodeHash) {
        if (this.teamCodeHashes.length === this.adventureTeams.length) {
          this.teamCodeHashes = [teamCodeHash]
        } else if (
          this.teamCodeHashes.includes(teamCodeHash)
        ) {
          this.teamCodeHashes = this.teamCodeHashes.filter(el => {
            return el !== teamCodeHash
          })
        } else {
          this.teamCodeHashes.push(teamCodeHash)
        }
      } else {
        this.teamCodeHashes = this.adventureTeams.map(adventureTeam => {
          return adventureTeam.teamCodeHash
        })
      }
    },
    adjustTimestamp () {
      this.loading = true
      this.errorMessage = null

      var datetimeInput = document.getElementById('datetime')
      var selectedDatetimeTimestamp = (new Date(datetimeInput.value)).toISOString()
      const masterFunctionAdmin = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunctionAdmin')
      masterFunctionAdmin({
        methodName: 'change-team-started-at',
        facilitatorKey: this.faciKey,
        teamCodeHashes: this.teamCodeHashes,
        remarks: this.remarks,
        startedAtTimestamp: selectedDatetimeTimestamp
      }).then(result => {
        this.$store.dispatch('getFaciData', this.faciKey)
        this.$buefy.toast.open({
          message: 'Successfully adjusted start time!',
          type: 'is-primary',
          queue: false
        })
        // this.stage = null
        this.remarks = null
        this.loading = false
      }).catch(err => {
        this.errorMessage = err.message
        this.loading = false
      })
    }
  },
  computed: {
    faciData () {
      return this.$store.state.faciData
    },
    sortedAdventureTeams () {
      return [...this.adventureTeams].sort((a, b) => {
        return a.stage - b.stage || a.teamName.localeCompare(b.teamName)
      })
    }
  },
  mounted () {
    this.teamCodeHashes = this.adventureTeams.map(adventureTeam => {
      return adventureTeam.teamCodeHash
    })

    document.addEventListener('wheel', function (event) {
      if (
        document.activeElement.type === 'number' &&
        document.activeElement.classList.contains('noscroll')
      ) {
        document.activeElement.blur()
      }
    })
  },
  watch: {
    adventureTeams (newAdventureTeams, oldAdventureTeams) {
      if (
        newAdventureTeams && oldAdventureTeams &&
        newAdventureTeams.length !== oldAdventureTeams.length
      ) {
        this.teamCodeHashes = newAdventureTeams.map(adventureTeam => {
          return adventureTeam.teamCodeHash
        })
      }
    }
    // stage (newValue, oldValue) {
    //   if (newValue !== oldValue) {
    //     this.stage = parseInt(newValue.toString())
    //   }
    // }
  }
}
</script>
