/* eslint-disable standard/no-callback-literal */
export default function throttle (callback, delay = 1000) {
  let shouldWait = false
  let callAgain = false

  return (...args) => {
    if (shouldWait) {
      callAgain = true
      return
    }
    callback(...args)
    shouldWait = true
    setTimeout(() => {
      shouldWait = false
      if (callAgain) {
        callAgain = false
        callback(...args)
      }
    }, delay)
  }
}
