<template>
  <div>
    <hero-bar>
      Privacy Policy and Service Level Agreement (SLA) for PLAYTOURS PTE. LTD.
    </hero-bar>

    <section class="section is-main-section">
      <h1><b>Privacy Policy and Service Level Agreement (SLA) for PLAYTOURS PTE. LTD.</b></h1>
      <br>

      <p>At PlayTours, accessible from https://playtours.app, https://admin.playtours.app, and https://get.playtours.app, one of our main priorities is the privacy of our visitors and the reliability of our services. This document contains details about our Privacy Policy, types of information collected and recorded by PlayTours, and our Service Level Agreement (SLA).</p>
      <br>

      <h2><b>Service Level Agreement (SLA)</b></h2>
      <br>

      <h3><b>Uptime Commitment</b></h3>
      <p>PlayTours commits to providing a minimum of 99% uptime for its services. This uptime percentage is calculated on a monthly basis and includes all aspects of the PlayTours platform.</p>
      <br>

      <h3><b>Service Availability</b></h3>
      <ul>
        <li><b>Normal Service Hours:</b> PlayTours services are available 24/7, excluding scheduled maintenance periods.</li>
        <li><b>Scheduled Maintenance:</b> Any scheduled maintenance will be communicated to users at least 48 hours in advance and will be conducted during non-peak hours to minimize impact.</li>
      </ul>
      <br>

      <h3><b>Support</b></h3>
      <ul>
        <li><b>Response Time:</b> PlayTours offers email support with a guaranteed initial response time within 24 hours during business days.</li>
        <li><b>Support Availability:</b> Support is available from Monday to Friday, 9 AM to 6 PM (SGT), excluding public holidays.</li>
      </ul>
      <br>

      <h3><b>Service Credits</b></h3>
      <p>If PlayTours fails to meet the 99% uptime commitment, users are eligible for service credits. The amount of service credits will be calculated as follows:</p>
      <ul>
        <li><b>99% - 95% Uptime:</b> 5% of the monthly fee credited to the next billing cycle.</li>
        <li><b>Below 95% Uptime:</b> 10% of the monthly fee credited to the next billing cycle.</li>
      </ul>
      <p>To request service credits, users must contact PlayTours support within 30 days of the end of the month in which the uptime commitment was not met.</p>
      <br>

      <h3><b>Breach Notification</b></h3>
      <p>In the event of any data breaches or if the SLA commitments are not met, PlayTours will notify affected users as soon as reasonably possible. This notification will include details about the breach or SLA issue, the steps being taken to resolve it, and any actions users may need to take.</p>
      <br>

      <h2><b>Privacy Policy</b></h2>
      <br>

      <p>If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.</p>

      <p>This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collected in PlayTours. This policy is not applicable to any information collected offline or via channels other than this website.</p>

      <br>
      <h2><b>Consent</b></h2>
      <br>

      <p>By using our website, you hereby consent to our Privacy Policy and agree to its terms.</p>

      <br>
      <h2><b>Information we collect</b></h2>
      <br>

      <p>The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.</p>
      <p>If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.</p>
      <br>
      <p>When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.</p>

      <br>
      <h2><b>How we use your information</b></h2>
      <br>

      <p>We use the information we collect in various ways, including to:</p>

      <ul>
      <li>- Provide, operate, and maintain our website.</li>
      <li>- Improve, personalize, and expand our website.</li>
      <li>- Understand and analyze how you use our website.</li>
      <li>- Develop new products, services, features, and functionality.</li>
      <li>- Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes.</li>
      <li>- Send you emails.</li>
      <li>- Find and prevent fraud.</li>
      </ul>

      <br>
      <h2><b>Customer Data confidentiality</b></h2>
      <br>

      <p>Customer Data that is based on or derived from you when creating games, and visitors who participated in games created by you, are wholly owned by you, and will not be shared with other Accounts in any capacity or format.</p>

      <p>As part of regular maintenance, PlayTours reserves the right to delete the Customer Data from our servers upon giving reasonable notice to you to download it before deletion.</p>

      <br>
      <h2><b>Log Files</b></h2>
      <br>

      <p>PlayTours follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.</p>

      <br>
      <h2><b>Cookies and Web Beacons</b></h2>
      <br>

      <p>Like any other website, PlayTours uses 'cookies'. These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.</p>

      <br>
      <h2><b>Advertising Partners</b></h2>
      <br>

      <p>We do not display advertisements on our website or on any part of our service. PlayTours does not sell or provide data obtained from your use of our website or services to any advertising partners.</p>
      <br>

      <h2><b>Third Party Privacy Policies</b></h2>
      <br>

      <p>PlayTours's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options. </p>

      <p>You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites.</p>

      <br>
      <h2><b>CCPA Privacy Rights (Do Not Sell My Personal Information)</b></h2>
      <br>

      <p>Under the CCPA, among other rights, California consumers have the right to:</p>
      <p>- Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.</p>
      <p>- Request that a business delete any personal data about the consumer that a business has collected.</p>
      <p>- Request that a business that sells a consumer's personal data, not sell the consumer's personal data.</p>
      <p>- If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</p>

      <br>
      <h2><b>GDPR Data Protection Rights</b></h2>
      <br>

      <p>We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:</p><br>
      <p>The right to access – You have the right to request copies of your personal data.</p><br>
      <p>The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.</p><br>
      <p>The right to erasure – You have the right to request that we erase your personal data, under certain conditions.</p><br>
      <p>The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.</p><br>
      <p>The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.</p><br>
      <p>The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</p><br>
      <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</p><br>

      <br>
      <h2><b>Children's Information</b></h2>
      <br>

      <p>Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.</p>
      <br>

      <p>PlayTours does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.</p>
    </section>
  </div>
</template>

<script>
import HeroBar from '@/components/HeroBar'

export default {
  name: 'PrivacyPolicy',
  components: {
    HeroBar
  },
  mounted () {
    this.$mixpanel.track('page_visit', {
      distinct_id: this.$store.state.profile.organisationId,
      organisation_name: this.$store.state.organisation.name,
      user_email: this.$store.state.profile.email,
      page_name: this.$route.name
    })
  }
}
</script>
