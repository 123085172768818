<template>
  <div>
    <b-table
      :checked-rows.sync="checkedRows"
      :checkable="checkable"
      :loading="isLoading"
      :paginated="paginated"
      :per-page="perPage"
      :striped="true"
      :hoverable="true"
      :data="masterTableRows"
    >
      <b-table-column label="Team" field="teamName" sortable v-slot="props">
        {{ props.row.teamName }}
      </b-table-column>
      <b-table-column v-if="showClans" label="Clan" field="clan" sortable v-slot="props">
        {{ props.row.clan }}
      </b-table-column>
      <b-table-column label="Team Members" field="teamMembersNames" sortable v-slot="props">
        {{ props.row.teamMembersNames.join(', ') }}
      </b-table-column>
      <b-table-column label="Total Points" field="points" sortable v-slot="props">
        {{ props.row.points }}
      </b-table-column>
      <b-table-column label="Chapter" field="stage" sortable v-slot="props">
        {{ !props.row.completedAt ? props.row.stage : 'COMPLETED' }}
      </b-table-column>
      <b-table-column label="Chapter Points" field="totalStagePoints" sortable v-slot="props">
        {{ props.row.stagePoints }}
      </b-table-column>
      <b-table-column label="Completion time" field="points" sortable v-slot="props">
        {{ props.row.timeTakenToComplete ? (props.row.timeTakenToComplete/60).toFixed(2) + ' mins' : '' }}
      </b-table-column>
      <b-table-column label="Updated On" field="updatedOn" sortable v-slot="props">
        {{ props.row.updatedOn ? new Date(props.row.updatedOn).toLocaleString('en-SG') : '' }}
      </b-table-column>
      <!-- <b-table-column custom-key="actions" cell-class="is-actions-cell" v-slot="props">
        <div class="buttons is-right">
          <router-link :to="{name:'managePlayTour', params: {adventureId: props.row.id}}" class="button is-small is-primary">
            <b-icon icon="account-edit" size="is-small"/>
          </router-link>
        </div>
      </b-table-column> -->

      <section slot="empty" class="section">
        <div class="content has-text-grey has-text-centered">
          <template v-if="isLoading">
            <p>
              <b-icon icon="dots-horizontal" size="is-large" />
            </p>
            <p>Fetching data...</p>
          </template>
          <template v-else>
            <p>
              <b-icon icon="emoticon-sad" size="is-large" />
            </p>
            <p>Nothing's here&hellip;</p>
          </template>
        </div>
      </section>
    </b-table>
  </div>
</template>

<script>
export default {
  name: 'AdventureTeamsTable',
  props: {
    adventureTeams: {
      type: Array,
      default: null
    },
    ranking: {
      type: Array,
      default: null
    },
    checkable: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isModalActive: false,
      trashObject: null,
      isLoading: false,
      paginated: true,
      perPage: 20,
      checkedRows: []
    }
  },
  computed: {
    showClans () {
      return this.adventureTeams.some(x => x.clan)
    },
    masterTableRows () {
      if (!this.adventureTeams) {
        return []
      } else {
        const masterTableRows = [...this.adventureTeams]
        console.log('masterTableRows', masterTableRows)
        masterTableRows.forEach(row => {
          const rankFiltered = this.ranking.filter(rank => {
            return rank.teamName === row.teamName
          })
          row.timeTakenToComplete = rankFiltered.length > 0 ? rankFiltered[0].timeTakenToComplete : null
          row.updatedOn = rankFiltered.length > 0 ? rankFiltered[0].updatedOn : null
        })
        masterTableRows.sort((a, b) => b.points - a.points)
        return masterTableRows
      }
    }
  }
}
</script>
