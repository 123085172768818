<template>
  <div class="container">
    <div id="map" class="map"></div>
  </div>
</template>

<script>
import L from 'leaflet'

export default {
  name: 'TeamsPositionsMap',
  props: ['adventureTeams', 'mapSettings'],
  mounted () {
    if (this.positions) {
      this.initMap()
    }
  },
  computed: {
    positions () {
      return this.adventureTeams.filter(el => {
        return el.position && el.position.lat && el.position.lon
      }).map(el => {
        return {
          lat: el.position.lat,
          lon: el.position.lon,
          teamName: el.teamName,
          updatedOnString: el.position.updatedOn ? (new Date(el.position.updatedOn._seconds * 1000)).toLocaleString('en-SG') : ''
        }
      })
    }
  },
  methods: {
    initMap () {
      let averageGeolocation = { lat: 0, lon: 0 }
      let location = [0, 0]
      let zoom = 16
      if (
        this.mapSettings
      ) {
        location = [
          this.mapSettings.lat ? this.mapSettings.lat : 0,
          this.mapSettings.lon ? this.mapSettings.lon : 0
        ]
        zoom = this.mapSettings.zoom ? this.mapSettings.zoom : 16
      } else if (this.positions.length > 0) {
        averageGeolocation = this.getAverageGeolocation(this.positions)
        this.$emit('updateMapSettings', {
          lat: averageGeolocation.lat,
          lon: averageGeolocation.lon,
          zoom
        })
        location = [averageGeolocation.lat, averageGeolocation.lon]
      }
      this.map = L.map('map').setView(location, zoom)
      this.tileLayer = L.tileLayer(
        'https://{s}.api.tomtom.com/map/1/tile/basic/main/{z}/{x}/{y}.png?key=OYQRCyzroGxySflITX5444WUUWwvcUjp'
      )
      //   const marker = L.marker(location, { draggable: false }).addTo(this.map)
      if (this.radius) {
        L.circle(location, this.radius).addTo(this.map)
      }
      this.positions.forEach(el => {
        const myIcon = L.divIcon({
          html: `<div><i class="fas fa-street-view fa-2x" style="color: #fc0303"></i><div class="has-text-weight-bold is-size-5">${el.teamName} <div class="has-text-weight-bold is-size-7">${el.updatedOnString}</div></div></div>`,
          iconSize: [20, 20],
          className: 'myDivIcon'
        })
        L.marker(
          [el.lat, el.lon],
          { icon: myIcon }
        ).addTo(this.map)
      })
      this.tileLayer.addTo(this.map)
      this.map.on('dragend', () => {
        this.$emit('updateMapSettings', {
          lat: this.map.getCenter().lat,
          lon: this.map.getCenter().lng,
          zoom: this.mapSettings ? this.mapSettings.zoom : null
        })
      })
      this.map.on('zoomend', () => {
        this.zoom = this.map.getZoom()
        this.$emit('updateMapSettings', {
          lat: this.mapSettings ? this.mapSettings.lat : null,
          lon: this.mapSettings ? this.mapSettings.lon : null,
          zoom: this.map.getZoom()
        })
      })
    },
    getAverageGeolocation (coords) {
      if (coords.length === 1) {
        return coords[0]
      }

      let x = 0.0
      let y = 0.0
      let z = 0.0

      for (const coord of coords) {
        const lat = coord.lat * Math.PI / 180
        const lon = coord.lon * Math.PI / 180

        x += Math.cos(lat) * Math.cos(lon)
        y += Math.cos(lat) * Math.sin(lon)
        z += Math.sin(lat)
      }

      const total = coords.length

      x = x / total
      y = y / total
      z = z / total

      const centralLongitude = Math.atan2(y, x)
      const centralSquareRoot = Math.sqrt(x * x + y * y)
      const centralLatitude = Math.atan2(z, centralSquareRoot)

      return {
        lat: centralLatitude * 180 / Math.PI,
        lon: centralLongitude * 180 / Math.PI
      }
    }
  }
}
</script>

<style>
.map {
  height: 450px;
}

.myDivIcon {
  text-align: center;
  line-height: 20px;
}
</style>
