<template>
  <div>
    <hero-bar>
      Files
      <router-link :to="{name:'addImage'}" slot="right" class="button is-primary">
        <i class="fas fa-upload mr-2"></i>
        Upload Files
      </router-link>
    </hero-bar>
    <section class="section is-main-section remove-max-width">
      <tiles>
        <files-view-module class="tile is-child" />
      </tiles>
    </section>
  </div>
</template>

<script>
import FilesViewModule from '@/components/FilesView'
import HeroBar from '@/components/HeroBar'
import Tiles from '@/components/Tiles'

export default {
  name: 'FilesView',
  components: {
    HeroBar,
    // ClientsTableSample,
    FilesViewModule,
    Tiles
    // Notification
  },
  data () {
    return {
      uid: this.$route.params.uid
    }
  },
  computed: {
    admin () {
      return this.$store.state.organisation.members.filter(el => {
        return el.uid === this.uid
      })[0]
    }
  },
  mounted () {
    this.$mixpanel.track('page_visit', {
      distinct_id: this.$store.state.profile.organisationId,
      organisation_name: this.$store.state.organisation.name,
      user_email: this.$store.state.profile.email,
      page_name: this.$route.name
    })
  }
}
</script>
