<template>
  <div>
    <modal-box
      :is-active="isModalActive"
      :trash-object-name="trashObjectName"
    />
    <b-table
      :checked-rows.sync="checkedRows"
      :checkable="checkable"
      :loading="isLoading"
      :paginated="paginated"
      :per-page="perPage"
      :striped="true"
      :hoverable="true"
      default-sort="createdAt"
      :data="tableData"
    >
      <!-- <b-table-column label="Team" field="teamName" sortable v-slot="props">
        {{ props.row.teamName }}
      </b-table-column> -->
      <!-- <b-table-column label="Challenge" field="challengeName" sortable v-slot="props">
        {{ props.row.challengeDetails.name }}
      </b-table-column> -->
      <b-table-column label="Details" field="briefing" v-slot="props">
        Team: <b>{{ props.row.teamName }}</b>
        <br>
        Task: <b>{{ props.row.challengeDetails.name }}</b>
        <br><br>
        <div v-markdown>{{ props.row.challengeDetails.briefing }}</div>
      </b-table-column>
      <b-table-column label="Submission" field="submission" v-slot="props">
        <div v-if="props.row.challengeDetails.type === 'judged-image'" class="container has-text-centered">
          <img
          :src="props.row.value"
          style="max-height: 400px;"
          >
        </div>
        <div v-if="props.row.challengeDetails.type === 'judged-video'" class="container has-text-centered">
          <video :src="props.row.value" width="320" height="240" controls>
            Your browser does not support the video tag.
          </video>
        </div>
        <div v-if="props.row.challengeDetails.type === 'judged-text'" class="container">
          <div>
            {{ props.row.value }}
          </div>
        </div>
      </b-table-column>
      <!-- <b-table-column label="Points Awarded" field="points" sortable v-slot="props">
        {{ props.row.pointsAwarded ? props.row.pointsAwarded : 'N/A' }}
      </b-table-column>
      <b-table-column label="Approved?" field="isApproved" sortable v-slot="props">
        {{ props.row.isApproved !== null ? props.row.isApproved : 'Pending' }}
      </b-table-column> -->
      <!-- <b-table-column label="Created At" field="createdAt" sortable v-slot="props">
        {{ new Date(props.row.createdAt).toLocaleString('en-SG') }}
      </b-table-column> -->
      <b-table-column label="Judge" custom-key="actions" cell-class="is-actions-cell" v-slot="props">
        <div class="">
          <div class="container mb-2">
            Approved? <br>
            <b-switch class="" v-model="approvals[`${props.row.judgementId}`]"></b-switch>
          </div>
          Points
          <b-input :placeholder="props.row.challengeDetails.points" v-model="points[`${props.row.judgementId}`]" type="number" class="mb-2" required />
          Comment
          <b-input placeholder="optional" v-model="comments[`${props.row.judgementId}`]" class="mr-1 mb-2" style="width: 100%;"/>
          <div
          @click="submitJudgement(props.row)"
          class="button is-primary"
          :class="{'is-loading': loading}"
          :disabled="loading || submitted.includes(props.row.judgementId) || !props.row.isPendingJudgement"
          >
            <i class="fas fa-gavel mr-2"></i>
            {{ submitted.includes(props.row.judgementId) || !props.row.isPendingJudgement ? 'Submitted' : 'Submit' }}
          </div>
          <!-- <router-link :to="{name:'submitJudgement', params: { judgementId: props.row.judgementId }}" class="button is-primary">
            <i class="fas fa-gavel"></i>
          </router-link> -->
        </div>
      </b-table-column>

      <section slot="empty" class="section">
        <div class="content has-text-grey has-text-centered">
          <template v-if="isLoading">
            <p>
              <b-icon icon="dots-horizontal" size="is-large" />
            </p>
            <p>Fetching data...</p>
          </template>
          <template v-else>
            <p>
              <b-icon icon="emoticon-sad" size="is-large" />
            </p>
            <p>Nothing's here&hellip;</p>
          </template>
        </div>
      </section>
    </b-table>
  </div>
</template>

<script>
import ModalBox from '@/components/ModalBox'
import firebaseApp from '@/firebase/init'

export default {
  name: 'JudgementsTable',
  components: { ModalBox },
  props: {
    tableData: {
      type: Array,
      default: null
    },
    checkable: {
      type: Boolean,
      default: false
    },
    faciKey: {
      type: String
    }
  },
  data () {
    return {
      loading: false,
      approvals: {},
      points: {},
      comments: {},
      submitted: [],
      isModalActive: false,
      trashObject: null,
      isLoading: false,
      paginated: true,
      perPage: 10,
      checkedRows: []
    }
  },
  computed: {
    trashObjectName () {
      if (this.trashObject) {
        return this.trashObject.name
      }
      return null
    }
  },
  watch: {
    tableData (newTableData) {
      this.fillData()
    },
    approvals: {
      handler (newValue) {
        console.log(newValue)
      },
      deep: true
    }
  },
  methods: {
    fillData () {
      this.tableData.forEach(row => {
        if (this.approvals[row.judgementId] === undefined) {
          this.approvals[row.judgementId] = true
        }
        if (!this.points[row.judgementId]) {
          // this.points[row.judgementId] = row.challengeDetails.points
        }
      })
    },
    submitJudgement (rowData) {
      if (!rowData.isPendingJudgement) {
        return
      }
      const judgementId = rowData.judgementId
      this.loading = true
      const isApproved = this.approvals[judgementId] ? this.approvals[judgementId] : false
      let pointsAwarded = 0
      if (isApproved && !this.points[judgementId]) {
        pointsAwarded = this.tableData.filter(row => row.judgementId === judgementId)[0].challengeDetails.points
      } else if (this.points[judgementId]) {
        pointsAwarded = this.points[judgementId]
      }
      const masterFunctionAdmin = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunctionAdmin')
      masterFunctionAdmin({
        methodName: 'submit-judgement',
        faciKey: this.faciKey,
        judgementId,
        comments: this.comments[judgementId],
        pointsAwarded,
        isApproved
      }).then(() => {
        this.submitted.push(judgementId)
        this.loading = false
        this.$buefy.toast.open({
          message: 'Successfully submitted judgement!',
          type: 'is-primary',
          queue: false
        })
      }).catch(() => {
        this.loading = false
      })
    }
  },
  mounted () {
    this.fillData()
  }
}
</script>
