<template>
  <div>
    <judgement-view :judgement="judgement" class="mb-4" />
    <judgement-answer :judgement="judgement" class="mb-4" />
    <judgement-submit-form
      :faciKey="faciKey"
      :judgement="judgement"
      class="is-child"
      v-on:close="$emit('close')"
    />
  </div>
</template>

<script>
import JudgementAnswer from '@/components/JudgementAnswer'
import JudgementView from '@/components/JudgementView'
import JudgementSubmitForm from '@/components/JudgementSubmitForm'

export default {
  name: 'Judgement',
  components: {
    JudgementAnswer,
    JudgementView,
    JudgementSubmitForm
  },
  props: {
    faciKeyProp: {
      type: String,
      default: null
    },
    judgementIdProp: {
      type: String,
      default: null
    }
  },
  computed: {
    faciKey () {
      return this.faciKeyProp ? this.faciKeyProp : this.$route.params.faciKey
    },
    judgementId () {
      return this.judgementIdProp ? this.judgementIdProp : this.$route.params.judgementId
    },
    judgement () {
      return this.$store.state.faciData ? this.$store.state.faciData.adventureJudgements.filter(el => el.judgementId === this.judgementId)[0] : null
    }
  }
}
</script>
