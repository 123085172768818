<template>
  <div>
    <hero-bar>
      Profile
    </hero-bar>
    <section class="section is-main-section">
      <tiles>
        <profile-update-form class="tile is-child" />
        <!-- <card-component title="Profile" icon="account" class="tile is-child">
          <user-avatar class="image has-max-width is-aligned-center" />
          <hr />
          <b-field label="Name">
            <b-input :value="userName" custom-class="is-static" readonly />
          </b-field>
          <hr />
          <b-field label="E-mail">
            <b-input :value="userEmail" custom-class="is-static" readonly />
          </b-field>
        </card-component> -->
      </tiles>
      <password-update-form />
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'
// import CardComponent from '@/components/CardComponent'
import HeroBar from '@/components/HeroBar'
import ProfileUpdateForm from '@/components/ProfileUpdateForm'
import PasswordUpdateForm from '@/components/PasswordUpdateForm'
import Tiles from '@/components/Tiles'
// import UserAvatar from '@/components/UserAvatar'
export default {
  name: 'Profile',
  components: {
    // UserAvatar,
    Tiles,
    PasswordUpdateForm,
    ProfileUpdateForm,
    HeroBar
    // CardComponent
  },
  computed: {
    email () {
      return this.$store.state.user.email
    },
    ...mapState(['userName', 'userEmail'])
  },
  mounted () {
    this.$mixpanel.track('page_visit', {
      distinct_id: this.$store.state.profile.organisationId,
      organisation_name: this.$store.state.organisation.name,
      user_email: this.$store.state.profile.email,
      page_name: this.$route.name
    })
  }
}
</script>
