<template>
  <card-component title="Broadcast Message" icon="fa-bullhorn" class="pb-5">
    <form v-if="form" class="mb-4" @submit.prevent="() => {}">
      <b-field horizontal label="Select teams">
        <div class="buttons">
          <div
          @click="selectTeam(null)"
          class="button is-small"
          :class="{'is-primary': teamCodeHashes.length === adventureTeams.length }"
          >
            ALL TEAMS
          </div>
          <div
          @click="selectTeam(adventureTeam.teamCodeHash)"
          v-for="(adventureTeam, index) in adventureTeams"
          :key="index"
          class="button is-small"
          :class="{'is-primary': teamCodeHashes.includes(adventureTeam.teamCodeHash) && teamCodeHashes.length !== adventureTeams.length }"
          >
            {{ adventureTeam.teamName }}
          </div>
        </div>
      </b-field>
      <b-field horizontal label="Message">
        <b-input v-model="form.text" name="message" type="textarea"/>
      </b-field>
      <b-field
        label="Send by email also"
        message="Message will also be sent via email, if teams/players have added their emails during registration. Can only be sent once every 15 minutes."
        horizontal
      >
        <b-switch v-model="form.isSendByEmail">
        </b-switch>
      </b-field>
      <b-field
        label="Do not interrupt"
        message="By default, messages from facilitators will block their screen and interrupt them to ensure that it is read as soon as possible. If this is enabled, they will not be interrupted, instead, they will see a red notifcation icon on their chat."
        horizontal
      >
        <b-switch v-model="form.isDoNotInterrupt">
        </b-switch>
      </b-field>
      <b-field horizontal>
        <div class="control">
          <div v-if="errorMessage" class="container">
            <div class="is-size-6 has-text-danger">
              {{ errorMessage }}
            </div>
          </div>
          <button
            @click="broadcastMessage"
            class="button is-primary"
            :class="{ 'is-loading': loading }"
            :disabled="!form.text || form.text.length === 0"
          >
            Broadcast Message
          </button>
        </div>
      </b-field>
    </form>
    <div v-if="faciData">
      <hr>
      <div class="is-size-5 has-text-weight-semibold">History</div>
      <div class="is-size-7 has-text-grey-light mb-3">Showing only messages by facilitators that are sent to every team and session-wide chats sent by teams.</div>
      <div v-for="(chat, index) in faciData.sessionWideChats.slice().reverse()" :key="index" class="">
        <div class="is-flex is-flex-wrap-wrap">
          <div v-if="chat.name" class="text-wrap">
            <b>{{ chat.name }} ({{ chat.teamName }}): </b>{{ chat.text }}
          </div>
          <div v-else>
            <b>Facilitator: </b>{{ chat.text }}
          </div>
          <div class="has-text-grey-light ml-1">
            {{ (new Date(chat.createdAt)).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}) }}
          </div>
        </div>
      </div>
    </div>
  </card-component>
</template>

<script>
import firebaseApp from '@/firebase/init'
import CardComponent from '@/components/CardComponent'

export default {
  name: 'BroadcastMessageForm',
  components: {
    CardComponent
  },
  props: {
    adventureTeams: {
      type: Array,
      default: null
    },
    faciKey: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      teamCodeHashes: [],
      errorMessage: null,
      loading: false,
      form: {
        text: null,
        isSendByEmail: false,
        isDoNotInterrupt: false
      }
    }
  },
  methods: {
    selectTeam (teamCodeHash) {
      if (teamCodeHash) {
        if (this.teamCodeHashes.length === this.adventureTeams.length) {
          this.teamCodeHashes = [teamCodeHash]
        } else if (
          this.teamCodeHashes.includes(teamCodeHash)
        ) {
          this.teamCodeHashes = this.teamCodeHashes.filter(el => {
            return el !== teamCodeHash
          })
        } else {
          this.teamCodeHashes.push(teamCodeHash)
        }
      } else {
        this.teamCodeHashes = this.adventureTeams.map(adventureTeam => {
          return adventureTeam.teamCodeHash
        })
      }
    },
    broadcastMessage () {
      this.loading = true
      this.errorMessage = null
      const masterFunctionAdmin = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunctionAdmin')
      masterFunctionAdmin({
        methodName: 'broadcast-message',
        facilitatorKey: this.faciKey,
        teamCodeHashes: this.teamCodeHashes,
        text: this.form.text,
        isDoNotInterrupt: this.form.isDoNotInterrupt,
        isSendByEmail: this.form.isSendByEmail
      }).then(result => {
        this.$buefy.toast.open({
          message: 'Successfully broadcasted message!',
          type: 'is-primary',
          queue: false
        })
        this.form.text = null
        this.loading = false
      }).catch(err => {
        this.errorMessage = err.message
        this.loading = false
      })
    }
  },
  mounted () {
    this.teamCodeHashes = this.adventureTeams.map(adventureTeam => {
      return adventureTeam.teamCodeHash
    })
  },
  computed: {
    faciData () {
      return this.$store.state.faciData
    }
  },
  watch: {
    adventureTeams (newAdventureTeams, oldAdventureTeams) {
      if (
        newAdventureTeams && oldAdventureTeams &&
        newAdventureTeams.length !== oldAdventureTeams.length
      ) {
        this.teamCodeHashes = newAdventureTeams.map(adventureTeam => {
          return adventureTeam.teamCodeHash
        })
      }
    }
  }
}
</script>
