<template>
  <card-component title="Toggle Task Categories" icon="fa-tasks" class="pb-5">
    <form @submit.prevent="() => {}">
      <b-field horizontal label="Categories">
        <div class="buttons">
          <div
          @click="selectTaskCategory(taskCategory)"
          v-for="(taskCategory, index) in faciData.taskCategories"
          :key="index"
          class="button"
          :class="{'is-primary': selectedTaskCategories.includes(taskCategory)}"
          >
            <i
            v-if="faciData.hiddenTaskCategories && faciData.hiddenTaskCategories.includes(taskCategory)"
            class="fas fa-eye-slash mr-2">
            </i>
            {{ taskCategory }}
          </div>
        </div>
      </b-field>
      <b-field horizontal>
        <div class="control">
          <div v-if="errorMessage" class="container">
            <div class="is-size-6 has-text-danger">
              {{ errorMessage }}
            </div>
          </div>
          <button
            @click="toggleTaskCategories"
            class="button is-primary"
            :class="{ 'is-loading': loading }"
            :disabled="selectedTaskCategories.length === 0"
          >
            Toggle Hidden Task Categor{{selectedTaskCategories.length > 1 ? 'ies' : 'y'}}
          </button>
        </div>
      </b-field>
    </form>
  </card-component>
</template>

<script>
import firebaseApp from '@/firebase/init'
import CardComponent from '@/components/CardComponent'

export default {
  name: 'ToggleTaskCategories',
  components: {
    CardComponent
  },
  props: {
    adventureTeams: {
      type: Array,
      default: null
    },
    faciKey: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      selectedTaskCategories: [],
      errorMessage: null,
      loading: false
    }
  },
  computed: {
    faciData () {
      return this.$store.state.faciData
    }
  },
  methods: {
    selectTaskCategory (taskCategory) {
      if (this.selectedTaskCategories.includes(taskCategory)) {
        this.selectedTaskCategories = this.selectedTaskCategories.filter(a => a !== taskCategory)
      } else {
        this.selectedTaskCategories.push(taskCategory)
      }
    },
    toggleTaskCategories () {
      this.loading = true
      this.errorMessage = null
      const masterFunctionAdmin = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunctionAdmin')
      masterFunctionAdmin({
        methodName: 'toggle-hidden-task-categories',
        facilitatorKey: this.faciKey,
        taskCategories: this.selectedTaskCategories
      }).then(result => {
        this.$buefy.toast.open({
          message: 'Successfully toggled task categories!',
          type: 'is-primary',
          queue: false
        })
        this.selectedTaskCategories = []
        this.$store.dispatch('getFaciData', this.faciKey)
        this.loading = false
      }).catch(err => {
        this.errorMessage = err.message
        this.loading = false
      })
    }
  },
  watch: {
    adventureTeams (newAdventureTeams, oldAdventureTeams) {
      if (
        newAdventureTeams && oldAdventureTeams &&
        newAdventureTeams.length !== oldAdventureTeams.length
      ) {
        this.teamCodeHashes = newAdventureTeams.map(adventureTeam => {
          return adventureTeam.teamCodeHash
        })
      }
    }
  }
}
</script>
