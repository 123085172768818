<template>
  <card-component title="Delete Organisation" icon="fa-trash-alt">
    <form v-if="form" @submit.prevent="() => {}">
      <b-field label="Type 'delete'"  message="All data related to the organisation, admins, games and files will be deleted permanently.">
        <b-input
        v-model="form.confirmDelete"
        name="confirmDelete"
        />
      </b-field>
      <hr />
      <b-field horizontal>
        <div class="control">
          <button
            @click="deleteOrganisation()"
            class="button is-danger"
            :class="{ 'is-loading': loading }"
            :disabled="!form.confirmDelete || form.confirmDelete.toLowerCase() !== 'delete'"
          >
            Delete organisation and all related data
          </button>
        </div>
      </b-field>
    </form>
  </card-component>
</template>

<script>
import firebaseApp from '@/firebase/init'
import CardComponent from '@/components/CardComponent'

export default {
  name: 'OrganisationDelete',
  components: {
    CardComponent
  },
  data () {
    return {
      loading: false,
      form: {
        confirmDelete: null
      }
    }
  },
  methods: {
    deleteOrganisation () {
      if (!this.form.confirmDelete || this.form.confirmDelete.toLowerCase() !== 'delete') {
        return
      }
      this.loading = true
      const masterFunctionAdmin = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunctionAdmin')
      masterFunctionAdmin({
        methodName: 'delete-organisation'
      }).then(result => {
        firebaseApp.auth().signOut().then(() => {
          this.$buefy.toast.open({
            message: 'Organisation deleted, logging out.',
            type: 'is-danger'
          })
          this.$router.push({ name: 'login' })
        })
      }).catch(() => {
        this.$buefy.toast.open({
          message: 'Error, please try again.',
          type: 'is-danger'
        })
        this.loading = false
      })
    }
  }
}
</script>
