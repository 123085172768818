<template>
  <div>
    <modal-box
      :is-active="isModalActive"
    />
    <b-table
      :checked-rows.sync="checkedRows"
      :checkable="checkable"
      :loading="isLoading"
      :paginated="paginated"
      :per-page="perPage"
      :striped="false"
      :hoverable="true"
      :data="sortedTableData"
    >
      <b-table-column label="" field="" v-slot="props">
        <div class="is-flex is-flex-wrap-wrap text-wrap">
          <span v-if="!props.row.repliedByFaci" class="tag is-danger is-light">waiting</span>
          <span v-else-if="props.row.repliedByFaci" class="tag is-success is-light">replied</span>
          <div class="has-text-grey mr-1">
            {{ new Date(props.row.createdAt).toLocaleTimeString('en-SG') }}
          </div>
          <b class="mr-1">
            {{ props.row.name }} ({{ props.row.teamName }}):
          </b>
          {{ props.row.text }}
        </div>
      </b-table-column>
      <b-table-column custom-key="actions" cell-class="is-actions-cell" v-slot="props">
        <div class="buttons is-mobile is-justify-content-flex-end my-1">
          <div
          v-if="!props.row.repliedByFaci && !replies[`${props.row.teamName}${props.row.createdAt}`]"
          @click="ignore(`${props.row.teamName}${props.row.createdAt}`, props.row.teamCodeHash, props.row.createdAt)"
          class="button is-primary is-clickable mb-0 mr-0"
          :class="{'is-loading': loading}"
          :disabled="loading"
          >
            <i class="fas fa-check-double mr-2"></i>
            Mark as read
          </div>
          <div
          @click="selectedTeamCodeHash = props.row.teamCodeHash"
          class="button is-primary is-clickable ml-2 mb-0"
          :class="{'is-loading': loading}"
          :disabled="loading"
          >
          <i class="fas fa-comments mr-2"></i>
            Open chat
          </div>
        </div>
      </b-table-column>

      <section slot="empty" class="section">
        <div class="content has-text-grey has-text-centered">
          <template v-if="isLoading">
            <p>
              <b-icon icon="dots-horizontal" size="is-large" />
            </p>
            <p>Fetching data...</p>
          </template>
          <template v-else>
            <p>
              <b-icon icon="emoticon-sad" size="is-large" />
            </p>
            <p>Nothing's here&hellip;</p>
          </template>
        </div>
      </section>
    </b-table>
    <div v-if="selectedTeamCodeHash" class="modal" :class="{'is-active': selectedTeamCodeHash}">
      <div @click="selectedTeamCodeHash = null" class="modal-background is-clickable"></div>
      <div
        class="modal-content"
        style="max-width: 800px; max-height: 80vh;"
      >
        <div
          class="modal-card my-0 mx-2 p-0"
          style="width: 95%; max-height: 80vh;"
        >
          <section class="modal-card-body" style="border-radius: 0.80rem;">
            <div
            v-for="(message, index) in [...tableData].sort((a, b) => a.createdAt.localeCompare(b.createdAt)).filter(x => x.teamCodeHash === selectedTeamCodeHash && (x.text || x.imageUrl))"
            :key="index"
            class="is-flex is-flex-wrap-wrap text-wrap mb-1"
            >
              <div class="has-text-grey-light mr-2">
                {{ (new Date(message.createdAt)).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}) }}
              </div>
              <b class="mr-1">{{ message.name ? `${message.name} (${message.teamName})` : 'Facilitator' }}:</b>
              {{ message.text }}
              <img v-if="message.imageUrl" :src="message.imageUrl" class="rounded-image" alt="" style="max-height: 300px;">
            </div>
            <div
            class="is-flex mt-2"
            >
              <b-input
                v-model="replies[`${selectedMessage.teamName}${selectedMessage.createdAt}`]"
                class="mr-2"
                name="tag"
                style="width: 100%;"
              />
              <div
              @click="reply(`${selectedMessage.teamName}${selectedMessage.createdAt}`, selectedMessage.teamCodeHash, selectedMessage.createdAt)"
              class="button is-primary is-clickable mb-0"
              :class="{'is-loading': loading}"
              :disabled="loading"
              >
              <i class="fas fa-paper-plane mr-2"></i>
                Send
              </div>
            </div>
            <div v-if="selectedAdventureTeamProgress" class="is-size-7 has-text-grey mt-2">
              {{ `Team is currently on Chapter ${selectedAdventureTeamProgress.stageIndex + 1}, with ${selectedAdventureTeamProgress.points} point(s)` }}
              <br>
              {{ `Last attempted task: "${selectedAdventureTeamProgress.name}" from Chapter ${selectedAdventureTeamProgress.stageIndex + 1} (${selectedAdventureTeamProgress.isCompleted ? 'completed' : 'incomplete'})` }}
            </div>
          </section>
        </div>
      </div>
      <button @click="selectedTeamCodeHash = null" class="modal-close is-large" aria-label="close"></button>
    </div>
  </div>
</template>

<script>
import firebaseApp from '@/firebase/init'
import ModalBox from '@/components/ModalBox'

export default {
  name: 'FaciMessagesTable',
  components: { ModalBox },
  props: {
    tableData: {
      type: Array,
      default: null
    },
    checkable: {
      type: Boolean,
      default: false
    },
    faciKey: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      loading: false,
      replies: {},
      selectedTeamCodeHash: null,
      isModalActive: false,
      isLoading: false,
      paginated: true,
      perPage: 20,
      checkedRows: []
    }
  },
  watch: {
    selectedTeamCodeHash: function (newVal, oldVal) {
      if (newVal !== null && oldVal === null) {
        this.$store.dispatch('getFaciData', this.faciKey)
      }
    }
  },
  computed: {
    faciData () {
      return this.$store.state.faciData
    },
    selectedAdventureTeamProgress () {
      if (!this.selectedTeamCodeHash) return null
      const adventureTeam = this.faciData.adventureTeams.find(x => x.teamCodeHash === this.selectedTeamCodeHash)
      if (adventureTeam && adventureTeam.latestAnswerLog) {
        const log = adventureTeam.latestAnswerLog
        return {
          stageIndex: log.stageIndex,
          challenge: log.challengeIndex,
          name: this.faciData.adventureDetails.stages[log.stageIndex].challenges[log.challengeIndex].name,
          isCompleted: !!log.isValidAnswer,
          points: adventureTeam.points
        }
      }
      return null
    },
    selectedMessage () {
      if (this.selectedTeamCodeHash) {
        return this.tableData.filter(x => x.teamCodeHash === this.selectedTeamCodeHash)[0]
      }
      return null
    },
    sortedTableData () {
      if (this.tableData && this.tableData.length > 0) {
        let tableDataToReturn = this.tableData.filter(x => {
          return x.name
          // return true
        }).sort((a, b) => {
          if (a.createdAt < b.createdAt) {
            return 1
          }
          if (a.createdAt > b.createdAt) {
            return -1
          }
          return 0
        })

        const addedTeamCodeHashes = []
        const mergedTableDataToReturn = []

        let finalMessage = ''
        tableDataToReturn.forEach((message, index) => {
          if (
            index + 1 < tableDataToReturn.length &&
            message.repliedByFaci === tableDataToReturn[index + 1].repliedByFaci &&
            message.name === tableDataToReturn[index + 1].name &&
            message.teamName === tableDataToReturn[index + 1].teamName
          ) {
            finalMessage = message.text + '; ' + finalMessage
          } else {
            finalMessage = message.text + '; ' + finalMessage
            finalMessage = finalMessage.substring(0, finalMessage.length - 2)
            if (!addedTeamCodeHashes.includes(message.teamCodeHash)) {
              const latestFaciMessage = this.tableData.find(x => {
                return x.teamCodeHash === message.teamCodeHash && !x.name
              })

              finalMessage = this.truncateString(finalMessage)
              if (latestFaciMessage && latestFaciMessage.text) {
                finalMessage += `; faci: ${latestFaciMessage.text}`
              }

              mergedTableDataToReturn.push({
                ...message,
                name: message.name,
                teamName: message.teamName,
                text: finalMessage
              })
              addedTeamCodeHashes.push(message.teamCodeHash)
            }
            finalMessage = ''
          }
        })
        tableDataToReturn = mergedTableDataToReturn

        return tableDataToReturn
      } else {
        return []
      }
    }
  },
  methods: {
    truncateString (str) {
      if (str.length > 35) {
        str = '...' + str.slice(-35)
      }
      return str
    },
    reply (index, teamCodeHash, createdAt) {
      if (!this.replies[index] || this.replies[index].length === 0) {
        this.$buefy.toast.open({
          message: 'Cannot send, please type a reply',
          type: 'is-warning',
          queue: false
        })
        return
      }
      this.loading = true
      const masterFunctionAdmin = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunctionAdmin')
      masterFunctionAdmin({
        methodName: 'broadcast-message',
        facilitatorKey: this.faciKey,
        teamCodeHashes: [teamCodeHash],
        text: this.replies[index]
      }).then(result => {
        this.replies[index] = ''
        this.$buefy.toast.open({
          message: 'Successfully replied!',
          type: 'is-primary',
          queue: false
        })
        this.loading = false
        this.$emit('refresh')
      }).catch(err => {
        this.errorMessage = err.message
        this.$buefy.toast.open({
          message: 'Error, please try again.',
          type: 'is-danger',
          queue: false
        })
        this.loading = false
      })
    },
    ignore (index, teamCodeHash, createdAt) {
      const confirmed = confirm('Mark message as read?')
      if (!confirmed) return
      this.loading = true
      const masterFunctionAdmin = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunctionAdmin')
      masterFunctionAdmin({
        methodName: 'broadcast-message',
        facilitatorKey: this.faciKey,
        teamCodeHashes: [teamCodeHash],
        text: null
      }).then(result => {
        this.replies[index] = ''
        this.$buefy.toast.open({
          message: 'Successfully marked as read!',
          type: 'is-primary',
          queue: false
        })
        this.loading = false
        this.$emit('refresh')
      }).catch(err => {
        this.errorMessage = err.message
        this.$buefy.toast.open({
          message: 'Error, please try again.',
          type: 'is-danger',
          queue: false
        })
        this.loading = false
      })
    }
  }
}
</script>
