<template>
  <card-component title="Judgement" icon="fa-gavel">
    <form class="p-5" v-if="judgement" @submit.prevent="() => {}">
      <b-field label="Approved?" message="Teams can re-attempt the task if not approved" horizontal>
        <b-switch v-model="isApproved">
        </b-switch>
      </b-field>
      <b-field horizontal label="Points to award" message="Only integers allowed, positive or negative. You can put a negative value as a penalty.">
        <b-input v-model="pointsAwarded" name="tag" type="number" :placeholder="judgement.challengeDetails.points" required />
      </b-field>
      <b-field horizontal label="Comments" message="Comments can be used to explain how the judgement was made. Will be shown to teams on the task screen.">
        <b-input v-model="comments" name="tag" type="text" />
      </b-field>
      <hr />
      <b-field horizontal>
        <div class="control">
          <div v-if="errorMessage" class="container">
            <div class="is-size-6 has-text-danger">
              {{ errorMessage }}
            </div>
          </div>
          <button
            @click="submitJudgement"
            class="button is-primary mr-2"
            :class="{ 'is-loading': loading }"
          >
            Submit Judgement
          </button>
        </div>
      </b-field>
    </form>
  </card-component>
</template>

<script>
import firebaseApp from '@/firebase/init'
import CardComponent from '@/components/CardComponent'

export default {
  name: 'JudgmentSubmitForm',
  components: {
    CardComponent
  },
  props: {
    judgement: {
      type: Object,
      default: null
    },
    faciKey: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      editedJudgement: null,
      errorMessage: null,
      loading: false,
      comments: null,
      pointsAwarded: this.judgement.challengeDetails.points,
      isApproved: true
    }
  },
  methods: {
    submitJudgement () {
      this.loading = true
      this.errorMessage = null
      const masterFunctionAdmin = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunctionAdmin')
      masterFunctionAdmin({
        methodName: 'submit-judgement',
        faciKey: this.faciKey,
        judgementId: this.judgement.judgementId,
        comments: this.comments,
        pointsAwarded: Number(this.pointsAwarded),
        isApproved: this.isApproved
      }).then(() => {
        this.loading = false
        this.$buefy.toast.open({
          message: 'Successfully submitted judgement!',
          type: 'is-primary',
          queue: false
        })
        // this.$router.push({ name: 'facilitator', params: { faciKey: this.faciKey } })
        this.$emit('close')
      }).catch(err => {
        this.errorMessage = err.message
        this.loading = false
      })
    },
    copyLink () {
      this.$buefy.toast.open({
        message: 'Link copied!',
        type: 'is-primary',
        queue: false
      })
    }
  },
  watch: {
    isApproved (newValue, oldValue) {
      if (newValue) {
        this.pointsAwarded = this.judgement.challengeDetails.points
      } else {
        this.pointsAwarded = null
      }
    }
  }
}
</script>
