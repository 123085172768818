<template>
  <div>
    <hero-bar>
      Organisation
    </hero-bar>
    <section class="section is-main-section pb-0">
      <organisation-form class="tile is-child" />
    </section>
    <section class="section is-main-section">
      <organisation-delete class="tile is-child" />
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'
// import CardComponent from '@/components/CardComponent'
import HeroBar from '@/components/HeroBar'
import OrganisationForm from '@/components/OrganisationForm'
import OrganisationDelete from '@/components/OrganisationDelete'

// import UserAvatar from '@/components/UserAvatar'
export default {
  name: 'Organisation',
  components: {
    HeroBar,
    OrganisationForm,
    OrganisationDelete
  },
  computed: {
    email () {
      return this.$store.state.user.email
    },
    ...mapState(['userName', 'userEmail'])
  },
  mounted () {
    this.$mixpanel.track('page_visit', {
      distinct_id: this.$store.state.profile.organisationId,
      organisation_name: this.$store.state.organisation.name,
      user_email: this.$store.state.profile.email,
      page_name: this.$route.name
    })
  }
}
</script>
