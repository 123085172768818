<template>
  <card-component icon="fa-bolt">
    <div class="has-text-centered mb-2">
      All our subscription tiers get the <b>same, robust set of features</b>
      <br>
      with no limitations other than the maximum total number of concurrent players
    </div>
    <div class="tags is-justify-content-center">
      <span class="tag is-rounded">Unlimited number of players per month</span>
      <span class="tag is-rounded">Unlimited games</span>
      <span class="tag is-rounded">Unlimited files</span>
      <span class="tag is-rounded">Brandable game lobby</span>
      <span class="tag is-rounded">Flexible game canvas</span>
      <span class="tag is-rounded">Real-time sync across playres</span>
      <span class="tag is-rounded">Show tasks only to specific players</span>
      <span class="tag is-rounded">Different Types of Answers for Tasks</span>
      <span class="tag is-rounded">Advanced item mechanics</span>
      <span class="tag is-rounded">Centralized Portal for Validating Answers</span>
      <span class="tag is-rounded">Automatically provide hints</span>
      <span class="tag is-rounded">Game timer</span>
      <span class="tag is-rounded">Live leaderboard</span>
      <span class="tag is-rounded">View other players' activity</span>
      <span class="tag is-rounded">Faci-to-teams messaging</span>
      <span class="tag is-rounded">Teams-to-faci messaging</span>
      <span class="tag is-rounded">Live team location</span>
    </div>
  </card-component>
</template>

<script>
import CardComponent from '@/components/CardComponent'

export default {
  name: 'SubscriptionNotice',
  components: {
    CardComponent
  }
}
</script>
