<template>
  <card-component title="Frequently Asked Questions" icon="fa-bolt">
    <div v-for="faq, key in faqs" :key="key" class="mb-3">
      <div class="has-text-weight-semibold">
        {{ faq.q }}
      </div>
      <div>
        {{ faq.a }}
      </div>
    </div>
  </card-component>
</template>

<script>
import CardComponent from '@/components/CardComponent'

export default {
  name: 'SubscriptionFaq',
  components: {
    CardComponent
  },
  data () {
    return {
      faqs: [
        {
          q: 'What counts as a "device"',
          a: 'It refers to a device being used to play a game. If there are multiple players using the same device, they are considered as 1 device.'
        },
        {
          q: 'What does "maximum concurrent active devices" mean?',
          a: 'It refers to the number of devices that are engaged across all games in your account. Whenever a player engages on the app with a device, they are considered to be active for 10 minutes. This means that if your account has reached the limit, a new device can only join a game after 1 other player stops engaging for the past 30 minutes.'
        },
        {
          q: 'I only need to use this for a day, can I pay only a portion of the fee?',
          a: 'No, however, you can schedule an auto-cancellation at the end of the subscription month at any point in time to prevent it from auto-renewing.'
        },
        {
          q: 'What happens if another player tries to join after exceeding the limit?',
          a: 'The player will be blocked from entering a game from their device. Existing players will never be kicked out of their game, therefore, if players who has already joined a game disengages for 10 minutes while new players join, they will still be able to continue playing if they return. This means that it is possible to temporarily exceed the limit.'
        },
        {
          q: 'Can I upgrade or downgrade / switch plans at any time?',
          a: 'You can immediately cancel your current subscription and select a different tier. However, please note that there will be no prorated refunds from the cancelled subscription tier. We encourage you to select the highest tier required for a given month.'
        },
        {
          q: 'Are there feature differences between the different tiers?',
          a: 'No, all tiers, including the free tier, have access to the same feature set.'
        }
      ]
    }
  }
}
</script>
