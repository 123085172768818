<template>
  <div>
    <div class="modal is-active">
      <div class="modal-background"></div>
      <div class="modal-content px-4">
        <div class="card has-text-centered" style="margin: auto; width: 250px;">
          <div class="card-content">
            <p class="subtitle" style="margin-bottom: 25px;">
            Still there?
            </p>
            <!-- <p class="title">
            {{ email }}
            </p> -->
            <div>
              <button
                @click="$router.go(-1)"
                class="button is-primary mr-2"
              >
                Yes, I am here!
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'FacilitatorActiveCheck',
  data () {
    return {

    }
  },
  methods: {
  }
}
</script>

<style>
.faci-columns {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 20px;
  width: 100%;
  justify-content: center;
}

.faci-column {
  width: 100%;
  margin-bottom: 20px;
  /* margin-right: 10px; */
  flex-grow: 1;
}
</style>
